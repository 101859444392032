import { useTranslation } from 'react-i18next';

import { RankBadge } from '@application/components';
import MatchingScore from '@application/components/matching/MatchingScore';
import { Offer, OfferStatusTypeCode, Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import CandidateLink from './CandidateLink';
import GeneralConditions from './GeneralConditions';
import RequestTypeCondition from './RequestTypeCondition';
import { TD_CLASS_NAME, TH_CLASS_NAME } from './constants';

type TableBodyProps = {
  request: Request;
  offers: readonly Offer[];
  checkedOffers: string[];
};

const TableBody = ({ request, offers, checkedOffers }: TableBodyProps) => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });

  const getCount = (countStr: string) => parseFloat(countStr).toFixed(2);

  return (
    <tbody>
      <tr>
        <th className={cn(TH_CLASS_NAME, 'pt-s-12')}>
          <span>{t('information.matchingScore')}</span>
        </th>
        {offers.map((offer) => (
          <td
            aria-label={t('information.matchingScore')}
            key={`matching-score-${offer.id}`}
            className={cn(TD_CLASS_NAME, {
              'border-x-2 !border-x-main': checkedOffers.includes(offer.id),
            })}
          >
            <MatchingScore value={Number(offer.matchingScore)} />
          </td>
        ))}
      </tr>

      <tr>
        <th className={TH_CLASS_NAME}>
          <span>{t('information.rank')}</span>
        </th>
        {offers.map((offer) => (
          <td
            aria-label={t('information.rank')}
            key={`rank-${offer.id}`}
            className={cn(TD_CLASS_NAME, {
              'border-x-2 !border-x-main': checkedOffers.includes(offer.id),
            })}
          >
            {offer.rank && <RankBadge rank={offer.rank} />}
          </td>
        ))}
      </tr>

      <tr>
        <th className={TH_CLASS_NAME}>
          <span>{t('information.totalCosts')}</span>
        </th>
        {offers.map((offer) => (
          <td
            aria-label={t('information.totalCosts')}
            key={`totalCosts-${offer.id}`}
            className={cn(TD_CLASS_NAME, {
              'border-x-2 !border-x-main': checkedOffers.includes(offer.id),
              'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
            })}
          >
            {offer.totalCosts ? (
              <span>
                {t('columns.totalCosts', {
                  count: getCount(offer.totalCosts) as unknown as number,
                })}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        ))}
      </tr>

      <tr>
        <th className={TH_CLASS_NAME}>
          <span>{t('information.remunerationType')}</span>
        </th>
        {offers.map((offer) => (
          <td
            aria-label={t('information.remunerationType')}
            key={`remunerationType-${offer.id}`}
            className={cn(TD_CLASS_NAME, {
              'border-x-2 !border-x-main': checkedOffers.includes(offer.id),
              'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
            })}
          >
            {offer.generalContractualConditions?.remunerationType ? (
              <span>
                {t(
                  `enum.conditionRemunerationType.${offer.generalContractualConditions.remunerationType.toLowerCase()}`
                )}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        ))}
      </tr>

      <GeneralConditions offers={offers} checkedOffers={checkedOffers} />

      {request.types?.map((type) => (
        <RequestTypeCondition
          key={type}
          type={type}
          offers={offers}
          checkedOffers={checkedOffers}
        />
      ))}

      <CandidateLink offers={offers} checkedOffers={checkedOffers} />
    </tbody>
  );
};

export default TableBody;
