import { ChangeEvent, useContext, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  CheckboxGroup,
  CreatableSelectField,
  FormInputWithSuffix,
  HelperText,
  Label,
  RadioGroup,
} from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { RangeSlider } from '@application/components/range-slider';
import type { OptionType } from '@application/components/select-field/select';
import { AccountContext } from '@application/context';
import { useGetPerks } from '@application/views/organization/profile/hooks';
import {
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';
import { INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX } from '@utils/yup-utils';

import { Section } from '../../components';
import {
  useGetJobDurations,
  useGetJobModes,
  useGetJobPaymentFrequencies,
  useGetJobPaymentOtherTypes,
  useGetJobScheduleTypes,
} from '../../hooks';
import { RequestFormFields } from '../../schema';

type MoreInformationFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  clearErrors: UseFormClearErrors<RequestFormFields>;
  setError: UseFormSetError<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  isEditing?: boolean;
};

const MoreInformationFields = ({
  control,
  errors,
  clearErrors,
  setError,
  setValue,
  register,
  trigger,
  watch,
  isEditing,
}: MoreInformationFieldsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('requests');
  const { account } = useContext(AccountContext);

  const { data: JOB_DURATIONS = [] } = useGetJobDurations();
  const { data: JOB_SCHEDULE_TYPES = [] } = useGetJobScheduleTypes();
  const { data: ACCOUNT_ADVANTAGE_TYPES = [] } = useGetPerks();
  const { data: JOB_MODES = [] } = useGetJobModes();
  const { data: JOB_PAYMENT_FREQUENCIES = [] } = useGetJobPaymentFrequencies();
  const { data: JOB_PAYMENT_OTHER_TYPES = [] } = useGetJobPaymentOtherTypes();

  const scheduleTypes = watch('jobWorkingHours') || [];
  const duration = watch('jobDurationCode');
  const mode = watch('jobModeCode');
  const paymentFrequency = watch('jobPaymentFrequencyCode');
  const paymentOtherTypes = watch('jobPaymentOtherTypesCodes') || [];
  const hourMin = watch('jobHourlyRateMin');
  const hourMax = watch('jobHourlyRateMax');
  const salaryMin = watch('jobSalaryMin');
  const salaryMax = watch('jobSalaryMax');
  const hoursPerWeek = watch('jobAvailabilityInHoursPerWeek');
  const perks = watch('perks') || [];
  const otherPerks = watch('otherPerks');

  const handleScheduleChange = (event: any): void => {
    if (event.target.checked) {
      setValue('jobWorkingHours', [...scheduleTypes, event.target.value]);
    } else {
      setValue('jobWorkingHours', [
        ...scheduleTypes.filter((s) => s !== event.target.value),
      ]);
    }
  };

  const handleOnPerksChange = (event: any): void => {
    if (event.target.checked) {
      setValue('perks', [...perks, event.target.value]);
    } else {
      setValue('perks', [...perks.filter((p) => p !== event.target.value)]);
    }
  };

  const handleOnPaymentOtherTypesChange = (event: any): void => {
    if (event.target.checked) {
      setValue('jobPaymentOtherTypesCodes', [
        ...paymentOtherTypes,
        event.target.value,
      ]);
    } else {
      setValue('jobPaymentOtherTypesCodes', [
        ...paymentOtherTypes.filter((p) => p !== event.target.value),
      ]);
    }
  };

  useEffect(() => {
    if (account?.advantageCodes && !isEditing) {
      setValue('perks', [...account.advantageCodes]);
    }
  }, [setValue, account?.advantageCodes, isEditing]);

  const hasAvailabilityErrors =
    !!errors.jobFullTimeAvailability || !!errors.jobPartTimeAvailability;

  // NOTE: filter on jobDurations because OpenEndedContract
  // is not applicable in requests
  return (
    <Section legend={t('subtitles.moreInformation')}>
      <Stack space={24}>
        <Controller
          name="jobDurationCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_DURATIONS.filter(
                (j) => j.value !== JobDurationCode.OpenEndedContract
              )}
              legend={t('labels.jobType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobDurationCode);
                setValue('jobDurationInMonths', undefined);
                trigger(name);
                trigger('jobDurationInMonths');
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.jobDurationCode}
              helperText={
                errors.jobDurationCode?.message &&
                tGlobal(errors.jobDurationCode?.message)
              }
            />
          )}
        />

        {(duration === JobDurationCode.Contract ||
          duration === JobDurationCode.Freelance ||
          duration === JobDurationCode.Internship) && (
          <FormInputWithSuffix
            label={t('labels.contractPeriod')}
            suffix={t('suffix.months')}
            type="number"
            minValue={1}
            invalid={!!errors.jobDurationInMonths}
            helperText={
              errors.jobDurationInMonths?.message &&
              tGlobal(errors.jobDurationInMonths?.message, { min: '1' })
            }
            {...register('jobDurationInMonths')}
          />
        )}
        <div>
          {/* Had to put the label outside CreatableSelectField to align with the other controls/error on the line and while resizing */}
          <Label
            htmlFor="jobAvailabilityInHoursPerWeek"
            invalid={!!errors.jobAvailabilityInHoursPerWeek}
          >
            {t('labels.hoursPerWeek')}
          </Label>
          <Cluster space={16} align="center">
            <Controller
              name="jobAvailabilityInHoursPerWeek"
              control={control}
              render={({ field: { name } }) => (
                <CreatableSelectField
                  name={name}
                  hideLabel
                  value={
                    hoursPerWeek?.map((v) => ({
                      label: v,
                      value: v,
                    })) as OptionType[]
                  }
                  className="flex-1 min-w-fit"
                  validateOnKeyDown={(newValue: string) =>
                    INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                  }
                  onInputChange={(newValue: string) => {
                    // Check if entered value is an integer or one decimal digit number only
                    // return an error otherwise
                    if (
                      newValue &&
                      !INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                    ) {
                      setError(name, {
                        type: 'custom',
                        message: tGlobal(
                          'validations.integerOrOneDecimalDigitNumber'
                        ),
                      });
                      return;
                    }
                    clearErrors(name);
                  }}
                  onChange={(options: any) => {
                    setValue(
                      name,
                      options.map((option: any) => option.value)
                    );
                    trigger(name);
                  }}
                  formatOptionLabel={({ value: newValue }) => `${newValue}h`}
                  invalid={!!errors.jobAvailabilityInHoursPerWeek}
                  helperText={
                    errors.jobAvailabilityInHoursPerWeek?.message
                      ? tGlobal(errors.jobAvailabilityInHoursPerWeek?.message, {
                          min: '1',
                        })
                      : tGlobal('creatableSelect.enterHoursAsIntegerOrDecimal')
                  }
                />
              )}
            />

            <div>
              <Cluster space={16} align="center">
                <Controller
                  name="jobFullTimeAvailability"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Checkbox
                      name={name}
                      value={String(value)}
                      label={t('labels.fullTime')}
                      onChange={(event) => {
                        onChange(event);
                        trigger('jobPartTimeAvailability');
                      }}
                      checked={Boolean(value)}
                      asButton
                    />
                  )}
                />

                <Controller
                  name="jobPartTimeAvailability"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Checkbox
                      name={name}
                      value={String(value)}
                      label={t('labels.partTime')}
                      onChange={(event) => {
                        onChange(event);
                        trigger('jobFullTimeAvailability');
                      }}
                      checked={Boolean(value)}
                      asButton
                    />
                  )}
                />
              </Cluster>

              {hasAvailabilityErrors && (
                <HelperText
                  id="jobAvailability"
                  invalid={hasAvailabilityErrors}
                >
                  {tGlobal('validations.required.jobAvailability')}
                </HelperText>
              )}
            </div>
          </Cluster>
        </div>

        <Controller
          name="jobWorkingHours"
          control={control}
          render={({ field: { value, name } }) => (
            <CheckboxGroup
              data={JOB_SCHEDULE_TYPES}
              legend={t('labels.scheduleMultiple')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleScheduleChange(event);
                trigger(name);
              }}
              legendSize="small"
              values={value}
              invalid={!!errors.jobWorkingHours}
              helperText={
                errors.jobWorkingHours?.message &&
                tGlobal(errors.jobWorkingHours?.message)
              }
            />
          )}
        />

        <Controller
          name="jobModeCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_MODES}
              legend={t('labels.remoteWork')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobModeCode);
                trigger(name);
                trigger('jobRemoteModeInDays');
                trigger('operationTerritoryCodes');
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.jobModeCode}
              helperText={
                errors.jobModeCode?.message &&
                tGlobal(errors.jobModeCode?.message)
              }
            />
          )}
        />

        {mode === JobModeCode.Hybrid && (
          <FormInputWithSuffix
            type="number"
            minValue={1}
            maxValue={7}
            label={t('labels.remoteDaysAllowed')}
            suffix={t('suffix.daysPerWeek')}
            invalid={!!errors.jobRemoteModeInDays}
            helperText={
              errors.jobRemoteModeInDays?.message &&
              tGlobal(errors.jobRemoteModeInDays?.message, {
                min: '1',
                max: '7',
              })
            }
            {...register('jobRemoteModeInDays')}
          />
        )}

        <Controller
          name="jobPaymentFrequencyCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_FREQUENCIES}
              legend={t('labels.remunerationType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentFrequencyCode);
                trigger(name);
                trigger('jobRemunerationAmount');
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.jobPaymentFrequencyCode}
              helperText={
                errors.jobPaymentFrequencyCode?.message &&
                tGlobal(errors.jobPaymentFrequencyCode?.message)
              }
            />
          )}
        />

        {paymentFrequency === JobPaymentFrequencyCode.Yearly && (
          <RangeSlider
            minLabel={t('labels.minSalary')}
            maxLabel={t('labels.maxSalary')}
            minName="jobSalaryMin"
            maxName="jobSalaryMax"
            onChange={(name: string, value: number | string) =>
              setValue(name as keyof RequestFormFields, value)
            }
            minValue={salaryMin || undefined}
            maxValue={salaryMax || undefined}
            min={1000}
            max={1000000}
            suffix="$"
          />
        )}

        {paymentFrequency === JobPaymentFrequencyCode.Hourly && (
          <RangeSlider
            minLabel={t('labels.minHourlyRate')}
            maxLabel={t('labels.maxHourlyRate')}
            minName="jobHourlyRateMin"
            maxName="jobHourlyRateMax"
            onChange={(name, value) => {
              setValue(name as keyof RequestFormFields, value);
            }}
            minValue={hourMin || undefined}
            maxValue={hourMax || undefined}
            min={0.01}
            max={999.99}
            step={0.01}
            suffix="$/h"
          />
        )}

        {paymentFrequency === JobPaymentFrequencyCode.FixedAmount && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.compensationAmount')}
            suffix={t('suffix.currency')}
            invalid={!!errors.jobRemunerationAmount}
            helperText={
              errors.jobRemunerationAmount?.message &&
              tGlobal(errors.jobRemunerationAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('jobRemunerationAmount')}
          />
        )}

        <Controller
          name="jobPaymentOtherTypesCodes"
          control={control}
          render={({ field: { value } }) => (
            <CheckboxGroup
              data={JOB_PAYMENT_OTHER_TYPES}
              legend={t('labels.otherCompensation')}
              language={extractLanguage(i18n.language)}
              onChange={handleOnPaymentOtherTypesChange}
              legendSize="small"
              values={value}
              invalid={!!errors.jobPaymentOtherTypesCodes}
              helperText={
                errors.jobPaymentOtherTypesCodes?.message &&
                tGlobal(errors.jobPaymentOtherTypesCodes?.message)
              }
            />
          )}
        />

        {paymentOtherTypes.includes(JobPaymentOtherTypesCode.HiringBonus) && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.otherCompensationAmount')}
            suffix={t('suffix.currency')}
            invalid={!!errors.jobOtherRemunerationAmount}
            helperText={
              errors.jobOtherRemunerationAmount?.message &&
              tGlobal(errors.jobOtherRemunerationAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('jobOtherRemunerationAmount')}
          />
        )}

        <Controller
          name="perks"
          control={control}
          render={({ field: { value, name } }) => (
            <CheckboxGroup
              data={ACCOUNT_ADVANTAGE_TYPES}
              legend={t('labels.perks')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleOnPerksChange(event);
                trigger(name);
              }}
              legendSize="small"
              values={value}
              invalid={!!errors.perks}
              helperText={
                errors.perks?.message && tGlobal(errors.perks?.message)
              }
            />
          )}
        />

        <Controller
          name="otherPerks"
          control={control}
          render={({ field: { name } }) => (
            <CreatableSelectField
              name={name}
              label={t('labels.otherPerks')}
              value={
                otherPerks?.map((s) => ({
                  label: s,
                  value: s,
                })) as OptionType[]
              }
              className="flex-1 min-w-fit"
              onChange={(options: any) => {
                setValue(
                  name,
                  options.map((option: any) => option.value)
                );
              }}
            />
          )}
        />
      </Stack>
    </Section>
  );
};

export default MoreInformationFields;
