import { useMemo } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CreatableSelectField,
  FormInputWithSuffix,
  SelectField,
  Switcher,
  TextInputField,
  Tooltip,
} from '@application/components';
import { OptionType } from '@application/components/select-field/select';
import { mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import useGetEducationLevels from '@application/views/recruitment/request/hooks/useGetEducationLevels';
import { JobModeCode } from '@domain/graphql.types';

import { OfferFormFields } from '../../../../types';

type JobInformationFieldsProps = {
  index: number;
};

const JobInformationFields = ({ index }: JobInformationFieldsProps) => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const citiesSubRegions =
    watch(`offerCandidates.${index}.citiesSubRegions`) || [];
  const mode = watch(`offerCandidates.${index}.jobModeCode`);

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();
  const OPERATION_TERRITORIES_OPTIONS = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  const { data: EDUCATION_LEVELS = [] } = useGetEducationLevels();
  const EDUCATION_LEVELS_OPTIONS = useMemo(
    () => mapOptions(EDUCATION_LEVELS, i18n.language),
    [EDUCATION_LEVELS, i18n]
  );

  return (
    <>
      <Switcher space={24} limit={2}>
        <FormInputWithSuffix
          type="number"
          label={
            <div className="flex items-center">
              {t('labels.experience')}
              <Tooltip message={t('labels.experienceTooltips')}>
                <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
              </Tooltip>
            </div>
          }
          minValue={1}
          maxValue={99}
          noMargin
          suffix={t('suffix.years')}
          alignLeft
          fullWidth
          mediumSuffix
          invalid={!!errors.offerCandidates?.[index]?.experience}
          helperText={
            errors.offerCandidates?.[index]?.experience?.message &&
            tGlobal(
              errors.offerCandidates?.[index]?.experience?.message as string
            )
          }
          {...register(`offerCandidates.${index}.experience`)}
        />

        <TextInputField
          label={
            <div className="flex items-center">
              {t('labels.specialty')}
              <Tooltip message={t('labels.specialtyTooltips')}>
                <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
              </Tooltip>
            </div>
          }
          invalid={!!errors.offerCandidates?.[index]?.specialty}
          helperText={
            errors.offerCandidates?.[index]?.specialty?.message &&
            tGlobal(
              errors.offerCandidates?.[index]?.specialty?.message as string,
              {
                max: '128',
              }
            )
          }
          {...register(`offerCandidates.${index}.specialty`)}
        />
      </Switcher>

      <Switcher space={24} limit={2}>
        <Controller
          name={`offerCandidates.${index}.educationLevelCode`}
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <SelectField
              className="w-full"
              label={t('labels.educationLevel')}
              name={name}
              options={EDUCATION_LEVELS_OPTIONS}
              value={
                EDUCATION_LEVELS_OPTIONS.find((o) => o.value === value) || null
              }
              onChange={(option: any) => {
                onChange(option.value);
                trigger(`offerCandidates.${index}.educationLevelCode`);
              }}
              invalid={!!errors.offerCandidates?.[index]?.educationLevelCode}
              helperText={
                errors.offerCandidates?.[index]?.educationLevelCode?.message &&
                tGlobal(
                  errors.offerCandidates?.[index]?.educationLevelCode
                    ?.message as string
                )
              }
            />
          )}
        />

        <TextInputField
          label={t('labels.studyField')}
          invalid={!!errors.offerCandidates?.[index]?.studyField}
          helperText={
            errors.offerCandidates?.[index]?.studyField?.message &&
            tGlobal(
              errors.offerCandidates?.[index]?.studyField?.message as string,
              { max: '128' }
            )
          }
          {...register(`offerCandidates.${index}.studyField`)}
        />
      </Switcher>
      {mode !== JobModeCode.Remote && (
        <>
          <Controller
            name={`offerCandidates.${index}.operationTerritoryCodes`}
            control={control}
            render={({ field: { onChange, onBlur, name, value } }) => (
              <SelectField
                label={t('labels.operationTerritory')}
                name={name}
                options={OPERATION_TERRITORIES_OPTIONS}
                value={
                  OPERATION_TERRITORIES_OPTIONS.filter((ot) =>
                    value?.includes(ot.value)
                  ) || null
                }
                onChange={(options: any) =>
                  onChange(
                    options
                      ? options.map((option: OptionType) => option.value)
                      : null
                  )
                }
                onBlur={onBlur}
                searchable
                clearable
                multiple
                limitTags={2}
                invalid={
                  !!errors.offerCandidates?.[index]?.operationTerritoryCodes
                }
                helperText={
                  errors.offerCandidates?.[index]?.operationTerritoryCodes
                    ?.message &&
                  tGlobal(
                    errors.offerCandidates?.[index]?.operationTerritoryCodes
                      ?.message as string
                  )
                }
              />
            )}
          />

          <Controller
            name={`offerCandidates.${index}.citiesSubRegions`}
            control={control}
            render={({ field: { name } }) => (
              <CreatableSelectField
                label={t('labels.citiesSubRegions')}
                name={name}
                value={
                  citiesSubRegions.map((s) => ({
                    label: s,
                    value: s,
                  })) as OptionType[]
                }
                className="flex-1 min-w-fit"
                onChange={(options: any) => {
                  setValue(
                    name,
                    options.map((option: any) => option.value)
                  );
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default JobInformationFields;
