import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { validate as isUUID } from 'uuid';

import {
  Badge,
  Button,
  ButtonLink,
  Cluster,
  ConfirmationModal,
  PageHeader,
  PageLoader,
  RankBadge,
  SelectField,
  Stack,
  Tooltip,
} from '@application/components';
import { PictureBadges } from '@application/components/picture-badges';
import { OptionType } from '@application/components/select-field/select';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import useAgencyProOfferValidation from '@application/views/billing/useAgencyProOfferValidation';
import useIsViewOnlySubscription from '@application/views/billing/useIsViewOnlySubscription';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';
import { getOfferDropDownStatusClassNames } from '@utils/styles-utils';

import { useDeleteOffer, useGetOffer, useUpdateOfferStatus } from './hooks';
import { CandidatesSummary, ConditionsSummary } from './summary';
import SalesPitch from './summary/salesPitch/SalesPitch';

const ViewOfferPage = () => {
  const { t, i18n } = useTranslation('offers');
  const { t: tGlobal } = useTranslation();
  const hasViewOnlyRestriction = useIsViewOnlySubscription();
  const { canPublishOffer } = useAgencyProOfferValidation();

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  const { deleteOffer } = useDeleteOffer();
  const { updateOfferStatus } = useUpdateOfferStatus();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchOffer,
  } = useGetOffer(id);

  const canEditOffer = useMemo(
    () =>
      data &&
      (data.status === OfferStatusTypeCode.Draft ||
        data.status === OfferStatusTypeCode.Published) &&
      !data.negotiationRoomId,
    [data]
  );

  const editbutton = useMemo(
    () => (
      <ButtonLink
        disabled={
          hasViewOnlyRestriction &&
          data &&
          data.status === OfferStatusTypeCode.Published
        }
        to={generatePath(PrivatePage.OFFER_EDIT, { id })}
        icon={<i className="ri-pencil-line" />}
      >
        {tGlobal('button.edit')}
      </ButtonLink>
    ),
    [hasViewOnlyRestriction, data, id, tGlobal]
  );

  const timeSinceCreated = useMemo(
    () => calculateTimeSince(data?.createdAt),
    [data?.createdAt]
  );

  const statusOptions = useMemo(() => {
    const options: OptionType[] = data?.status
      ? [
          {
            value: data?.status,
            label: t(`enum.offerStatusTypeCode.${data?.status.toLowerCase()}`),
          },
        ]
      : [];
    data?.availableTransitions.map((a) =>
      options.push({
        value: a,
        label: t(`enum.offerStatusTypeCode.${a.toLowerCase()}`),
      })
    );
    return options;
  }, [data, t]);

  const handleStatusUpdate = useCallback(
    async (status: OfferStatusTypeCode) => {
      await updateOfferStatus({
        offerStatusUpdateInput: { id, status },
      });
      refetchOffer();
    },
    [updateOfferStatus, id, refetchOffer]
  );

  const handleDelete = useCallback(async () => {
    setModal(null);
    await deleteOffer({ input: { offerId: id } });
  }, [deleteOffer, setModal, id]);

  const confirmDelete = useCallback(() => {
    setModal({
      title: t('modal.deleteOffer'),
      content: (
        <ConfirmationModal
          content={t('modal.confirmDeleteOffer')}
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
        />
      ),
    });
  }, [setModal, t, handleDelete]);

  const statusClassName = getOfferDropDownStatusClassNames(
    data?.status || OfferStatusTypeCode.Draft
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={<span className="h3">{t('viewOffer.title')}</span>}
        backTo={RootPrivatePage.OFFERS}
      >
        {data?.negotiationRoomId && (
          <ButtonLink
            primary
            icon={<i className="ri-shake-hands-line" />}
            to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_DETAILS, {
              id: data?.negotiationRoomId || null,
            })}
            from={location.pathname}
          >
            {t('button.negotiationsZone')}
          </ButtonLink>
        )}
      </PageHeader>

      <Stack space={24}>
        <Cluster className="justify-between mb-s-24">
          <div className="flex flex-row flex-wrap gap-s-24 items-center">
            <RankBadge
              rank={Number(data?.rank)}
              className="min-w-[2rem] min-h-[2rem] text-center"
            />
            <h2 className="text-24 font-semibold">
              {data?.recruitmentOpportunity?.jobSpecialtyCode
                ? getLocalizedDescription(
                    data?.recruitmentOpportunity?.jobSpecialty?.descriptions,
                    i18n.language
                  )
                : '-'}
            </h2>
            {data?.status === OfferStatusTypeCode.Draft &&
            !hasViewOnlyRestriction &&
            canPublishOffer ? (
              <SelectField
                label="status"
                hideLabel
                name="status"
                controlClassName={statusClassName}
                options={statusOptions}
                value={statusOptions.find(
                  (o: OptionType) => o.value === data?.status
                )}
                onChange={(option: any) =>
                  handleStatusUpdate(option.value as OfferStatusTypeCode)
                }
              />
            ) : (
              <Badge
                value={t(
                  `enum.offerStatusTypeCode.${data?.status.toLowerCase()}`
                )}
                placeholder="-"
                className={cn(
                  getOfferDropDownStatusClassNames(
                    data?.status || OfferStatusTypeCode.Draft
                  ),
                  'rounded-md h-s-48 w-s-112'
                )}
                valueClassName="text-16 font-semibold"
              />
            )}
            <PictureBadges
              pictures={[
                {
                  name: data?.user.name ?? undefined,
                  url: data?.user.avatarUrl,
                },
              ]}
              rounded="rounded-md"
              tooltipPosition="right"
            />
            <div className="flex items-center">
              <span>
                {timeSinceCreated.value > 1
                  ? tGlobal('date.since', {
                      date: tGlobal(`date.options.${timeSinceCreated.unit}`, {
                        count: timeSinceCreated.value,
                      }),
                    })
                  : tGlobal('date.today')}
              </span>
            </div>
          </div>

          <div>
            {data?.recruitmentOpportunity?.id && (
              <ButtonLink
                to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, {
                  id: data?.recruitmentOpportunity?.id,
                })}
                className={canEditOffer ? 'mr-s-16' : ''}
                from={location.pathname}
              >
                {t('button.opportunityDetails')}
              </ButtonLink>
            )}
            {canEditOffer && (
              <>
                <Button
                  icon={<i className="ri-delete-bin-line" />}
                  className="mr-s-16"
                  onClick={confirmDelete}
                >
                  {tGlobal('button.delete')}
                </Button>

                {hasViewOnlyRestriction &&
                data &&
                data.status === OfferStatusTypeCode.Published ? (
                  <Tooltip
                    message={tGlobal('membership.permission.upgradePlan')}
                  >
                    <div className="inline-flex">{editbutton}</div>
                  </Tooltip>
                ) : (
                  editbutton
                )}
              </>
            )}
          </div>
        </Cluster>
      </Stack>

      <CandidatesSummary offer={data} />
      <SalesPitch offer={data} />
      <ConditionsSummary offer={data} />
    </>
  );
};

export default ViewOfferPage;
